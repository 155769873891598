import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export function Login() {
  const {
    loginWithRedirect,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    const updateToken = async () => {
      try {
        await getAccessTokenSilently();
      } catch {
        loginWithRedirect();
      }
    };

    if (!isLoading) {
      if (isAuthenticated) {
        updateToken();
      } else {
        loginWithRedirect();
      }
    }
  }, [isLoading, loginWithRedirect, isAuthenticated, getAccessTokenSilently]);

  return <></>;
}
